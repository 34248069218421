@import 'src/scss/variables';
@import 'src/scss/utilities/breakpoints';

$logoWidth: 100px;
$logoWidthNegative: -#{$logoWidth};
$logoHeight: 64px;
$animationSpeed: 60s;


@keyframes mosh-benefits-infinite-auto-scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc($logoWidth * var(--info-grid-count) * -1)); /* single slider width (including margin) * number of slides * direction - _mosh_benefits.scss - ln 32 */
    }
}



.info-grid {
    padding: 0 auto;
    overflow: hidden;
    position: relative;
    @include xl-up {
        overflow: visible;
    }
    @include ipad-landscape-only {
        overflow: hidden;
    }
}
.info-grid__track {
    display: flex;
    width: calc($logoWidth * var(--info-grid-count));
    @include lg-up {
        justify-content: center;
        width: 100%;
    }
}

.info-grid__carousel {
    animation: mosh-benefits-infinite-auto-scroll $animationSpeed linear infinite;
}

.info-grid__item {
    height: auto;
    width: $logoWidth;
    flex-shrink: 0;
    margin-right: 0;
    pointer-events: none;
    &:last-child {
        margin-right: 0;
    }
    img {
        height: 2rem;
        width: auto;
        margin: 0 auto 1rem auto;
        @include lg-up {
            height: 3rem;
        }
    }
    span {
        font-size: $font-xsmall;
        @include lg-up {
            font-size: $font-small;
        }
        display: block;
    }
    @include lg-up {
        width: 120px;
        margin-right: 1rem;
    }
}

